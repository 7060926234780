let style;


/* ------ THEME: light ------ */

style = document.createElement('style');
style.dataset.theme = 'light';
style.textContent += require('./themes/light.js').default;
document.head.appendChild(style);



/* ------ THEME: dark ------ */

style = document.createElement('style');
style.dataset.theme = 'dark';
style.textContent += require('./themes/dark.js').default;
document.head.appendChild(style);


/* ------ THEME: original ------ */

style = document.createElement('style');
style.dataset.theme = 'original';
style.textContent += '';
document.head.appendChild(style);
