export default `
html[data-color-mode="light"][data-theme~="light:light"],
html[data-color-mode="dark"][data-theme~="dark:light"] {
  color-scheme: light;
  --ds-text: #172B4D;
  --ds-text-accent-lime: #4C6B1F;
  --ds-text-accent-lime-bolder: #37471F;
  --ds-text-accent-red: #AE2A19;
  --ds-text-accent-red-bolder: #601E16;
  --ds-text-accent-orange: #974F0C;
  --ds-text-accent-orange-bolder: #5F3811;
  --ds-text-accent-yellow: #7F5F01;
  --ds-text-accent-yellow-bolder: #533F04;
  --ds-text-accent-green: #216E4E;
  --ds-text-accent-green-bolder: #164B35;
  --ds-text-accent-teal: #206B74;
  --ds-text-accent-teal-bolder: #1D474C;
  --ds-text-accent-blue: #0055CC;
  --ds-text-accent-blue-bolder: #09326C;
  --ds-text-accent-purple: #5E4DB2;
  --ds-text-accent-purple-bolder: #352C63;
  --ds-text-accent-magenta: #943D73;
  --ds-text-accent-magenta-bolder: #50253F;
  --ds-text-accent-gray: #44546F;
  --ds-text-accent-gray-bolder: #091E42;
  --ds-text-disabled: #091E424F;
  --ds-text-inverse: #FFFFFF;
  --ds-text-selected: #0C66E4;
  --ds-text-brand: #0C66E4;
  --ds-text-danger: #AE2A19;
  --ds-text-warning: #974F0C;
  --ds-text-warning-inverse: #172B4D;
  --ds-text-success: #216E4E;
  --ds-text-discovery: #5E4DB2;
  --ds-text-information: #0055CC;
  --ds-text-subtlest: #626F86;
  --ds-text-subtle: #44546F;
  --ds-link: #0C66E4;
  --ds-link-pressed: #0055CC;
  --ds-icon: #44546F;
  --ds-icon-accent-lime: #6A9A23;
  --ds-icon-accent-red: #E34935;
  --ds-icon-accent-orange: #D97008;
  --ds-icon-accent-yellow: #B38600;
  --ds-icon-accent-green: #22A06B;
  --ds-icon-accent-teal: #1D9AAA;
  --ds-icon-accent-blue: #1D7AFC;
  --ds-icon-accent-purple: #8270DB;
  --ds-icon-accent-magenta: #CD519D;
  --ds-icon-accent-gray: #758195;
  --ds-icon-disabled: #091E424F;
  --ds-icon-inverse: #FFFFFF;
  --ds-icon-selected: #0C66E4;
  --ds-icon-brand: #0C66E4;
  --ds-icon-danger: #E34935;
  --ds-icon-warning: #D97008;
  --ds-icon-warning-inverse: #172B4D;
  --ds-icon-success: #22A06B;
  --ds-icon-discovery: #8270DB;
  --ds-icon-information: #1D7AFC;
  --ds-icon-subtle: #626F86;
  --ds-border: #091E4224;
  --ds-border-accent-lime: #6A9A23;
  --ds-border-accent-red: #E34935;
  --ds-border-accent-orange: #D97008;
  --ds-border-accent-yellow: #B38600;
  --ds-border-accent-green: #22A06B;
  --ds-border-accent-teal: #1D9AAA;
  --ds-border-accent-blue: #1D7AFC;
  --ds-border-accent-purple: #8270DB;
  --ds-border-accent-magenta: #CD519D;
  --ds-border-accent-gray: #758195;
  --ds-border-disabled: #091E420F;
  --ds-border-focused: #388BFF;
  --ds-border-input: #091E4224;
  --ds-border-inverse: #FFFFFF;
  --ds-border-selected: #0C66E4;
  --ds-border-brand: #0C66E4;
  --ds-border-danger: #E34935;
  --ds-border-warning: #D97008;
  --ds-border-success: #22A06B;
  --ds-border-discovery: #8270DB;
  --ds-border-information: #1D7AFC;
  --ds-border-bold: #758195;
  --ds-background-accent-lime-subtlest: #EEFBDA;
  --ds-background-accent-lime-subtlest-hovered: #D3F1A7;
  --ds-background-accent-lime-subtlest-pressed: #B3DF72;
  --ds-background-accent-lime-subtler: #D3F1A7;
  --ds-background-accent-lime-subtler-hovered: #B3DF72;
  --ds-background-accent-lime-subtler-pressed: #94C748;
  --ds-background-accent-lime-subtle: #94C748;
  --ds-background-accent-lime-subtle-hovered: #B3DF72;
  --ds-background-accent-lime-subtle-pressed: #D3F1A7;
  --ds-background-accent-lime-bolder: #5B7F24;
  --ds-background-accent-lime-bolder-hovered: #4C6B1F;
  --ds-background-accent-lime-bolder-pressed: #37471F;
  --ds-background-accent-red-subtlest: #FFEDEB;
  --ds-background-accent-red-subtlest-hovered: #FFD2CC;
  --ds-background-accent-red-subtlest-pressed: #FF9C8F;
  --ds-background-accent-red-subtler: #FFD2CC;
  --ds-background-accent-red-subtler-hovered: #FF9C8F;
  --ds-background-accent-red-subtler-pressed: #F87462;
  --ds-background-accent-red-subtle: #F87462;
  --ds-background-accent-red-subtle-hovered: #FF9C8F;
  --ds-background-accent-red-subtle-pressed: #FFD2CC;
  --ds-background-accent-red-bolder: #CA3521;
  --ds-background-accent-red-bolder-hovered: #AE2A19;
  --ds-background-accent-red-bolder-pressed: #601E16;
  --ds-background-accent-orange-subtlest: #FFF4E5;
  --ds-background-accent-orange-subtlest-hovered: #FFE2BD;
  --ds-background-accent-orange-subtlest-pressed: #FEC57B;
  --ds-background-accent-orange-subtler: #FFE2BD;
  --ds-background-accent-orange-subtler-hovered: #FEC57B;
  --ds-background-accent-orange-subtler-pressed: #FAA53D;
  --ds-background-accent-orange-subtle: #FAA53D;
  --ds-background-accent-orange-subtle-hovered: #FEC57B;
  --ds-background-accent-orange-subtle-pressed: #FFE2BD;
  --ds-background-accent-orange-bolder: #B65C02;
  --ds-background-accent-orange-bolder-hovered: #974F0C;
  --ds-background-accent-orange-bolder-pressed: #5F3811;
  --ds-background-accent-yellow-subtlest: #FFF7D6;
  --ds-background-accent-yellow-subtlest-hovered: #F8E6A0;
  --ds-background-accent-yellow-subtlest-pressed: #F5CD47;
  --ds-background-accent-yellow-subtler: #F8E6A0;
  --ds-background-accent-yellow-subtler-hovered: #F5CD47;
  --ds-background-accent-yellow-subtler-pressed: #E2B203;
  --ds-background-accent-yellow-subtle: #E2B203;
  --ds-background-accent-yellow-subtle-hovered: #F5CD47;
  --ds-background-accent-yellow-subtle-pressed: #F8E6A0;
  --ds-background-accent-yellow-bolder: #946F00;
  --ds-background-accent-yellow-bolder-hovered: #7F5F01;
  --ds-background-accent-yellow-bolder-pressed: #533F04;
  --ds-background-accent-green-subtlest: #DFFCF0;
  --ds-background-accent-green-subtlest-hovered: #BAF3DB;
  --ds-background-accent-green-subtlest-pressed: #7EE2B8;
  --ds-background-accent-green-subtler: #BAF3DB;
  --ds-background-accent-green-subtler-hovered: #7EE2B8;
  --ds-background-accent-green-subtler-pressed: #4BCE97;
  --ds-background-accent-green-subtle: #4BCE97;
  --ds-background-accent-green-subtle-hovered: #7EE2B8;
  --ds-background-accent-green-subtle-pressed: #BAF3DB;
  --ds-background-accent-green-bolder: #1F845A;
  --ds-background-accent-green-bolder-hovered: #216E4E;
  --ds-background-accent-green-bolder-pressed: #164B35;
  --ds-background-accent-teal-subtlest: #E3FAFC;
  --ds-background-accent-teal-subtlest-hovered: #C1F0F5;
  --ds-background-accent-teal-subtlest-pressed: #8BDBE5;
  --ds-background-accent-teal-subtler: #C1F0F5;
  --ds-background-accent-teal-subtler-hovered: #8BDBE5;
  --ds-background-accent-teal-subtler-pressed: #60C6D2;
  --ds-background-accent-teal-subtle: #60C6D2;
  --ds-background-accent-teal-subtle-hovered: #8BDBE5;
  --ds-background-accent-teal-subtle-pressed: #C1F0F5;
  --ds-background-accent-teal-bolder: #1D7F8C;
  --ds-background-accent-teal-bolder-hovered: #206B74;
  --ds-background-accent-teal-bolder-pressed: #1D474C;
  --ds-background-accent-blue-subtlest: #E9F2FF;
  --ds-background-accent-blue-subtlest-hovered: #CCE0FF;
  --ds-background-accent-blue-subtlest-pressed: #85B8FF;
  --ds-background-accent-blue-subtler: #CCE0FF;
  --ds-background-accent-blue-subtler-hovered: #85B8FF;
  --ds-background-accent-blue-subtler-pressed: #579DFF;
  --ds-background-accent-blue-subtle: #579DFF;
  --ds-background-accent-blue-subtle-hovered: #85B8FF;
  --ds-background-accent-blue-subtle-pressed: #CCE0FF;
  --ds-background-accent-blue-bolder: #0C66E4;
  --ds-background-accent-blue-bolder-hovered: #0055CC;
  --ds-background-accent-blue-bolder-pressed: #09326C;
  --ds-background-accent-purple-subtlest: #F3F0FF;
  --ds-background-accent-purple-subtlest-hovered: #DFD8FD;
  --ds-background-accent-purple-subtlest-pressed: #B8ACF6;
  --ds-background-accent-purple-subtler: #DFD8FD;
  --ds-background-accent-purple-subtler-hovered: #B8ACF6;
  --ds-background-accent-purple-subtler-pressed: #9F8FEF;
  --ds-background-accent-purple-subtle: #9F8FEF;
  --ds-background-accent-purple-subtle-hovered: #B8ACF6;
  --ds-background-accent-purple-subtle-pressed: #DFD8FD;
  --ds-background-accent-purple-bolder: #6E5DC6;
  --ds-background-accent-purple-bolder-hovered: #5E4DB2;
  --ds-background-accent-purple-bolder-pressed: #352C63;
  --ds-background-accent-magenta-subtlest: #FFECF8;
  --ds-background-accent-magenta-subtlest-hovered: #FDD0EC;
  --ds-background-accent-magenta-subtlest-pressed: #F797D2;
  --ds-background-accent-magenta-subtler: #FDD0EC;
  --ds-background-accent-magenta-subtler-hovered: #F797D2;
  --ds-background-accent-magenta-subtler-pressed: #E774BB;
  --ds-background-accent-magenta-subtle: #E774BB;
  --ds-background-accent-magenta-subtle-hovered: #F797D2;
  --ds-background-accent-magenta-subtle-pressed: #FDD0EC;
  --ds-background-accent-magenta-bolder: #AE4787;
  --ds-background-accent-magenta-bolder-hovered: #943D73;
  --ds-background-accent-magenta-bolder-pressed: #50253F;
  --ds-background-accent-gray-subtlest: #F1F2F4;
  --ds-background-accent-gray-subtlest-hovered: #DCDFE4;
  --ds-background-accent-gray-subtlest-pressed: #B3B9C4;
  --ds-background-accent-gray-subtler: #DCDFE4;
  --ds-background-accent-gray-subtler-hovered: #B3B9C4;
  --ds-background-accent-gray-subtler-pressed: #8590A2;
  --ds-background-accent-gray-subtle: #8590A2;
  --ds-background-accent-gray-subtle-hovered: #B3B9C4;
  --ds-background-accent-gray-subtle-pressed: #DCDFE4;
  --ds-background-accent-gray-bolder: #626F86;
  --ds-background-accent-gray-bolder-hovered: #44546F;
  --ds-background-accent-gray-bolder-pressed: #2C3E5D;
  --ds-background-disabled: #091E4208;
  --ds-background-input: #FFFFFF;
  --ds-background-input-hovered: #F7F8F9;
  --ds-background-input-pressed: #FFFFFF;
  --ds-background-inverse-subtle: #00000029;
  --ds-background-inverse-subtle-hovered: #0000003D;
  --ds-background-inverse-subtle-pressed: #00000052;
  --ds-background-neutral: #091E420F;
  --ds-background-neutral-hovered: #091E4224;
  --ds-background-neutral-pressed: #091E424F;
  --ds-background-neutral-subtle: #00000000;
  --ds-background-neutral-subtle-hovered: #091E420F;
  --ds-background-neutral-subtle-pressed: #091E4224;
  --ds-background-neutral-bold: #44546F;
  --ds-background-neutral-bold-hovered: #2C3E5D;
  --ds-background-neutral-bold-pressed: #172B4D;
  --ds-background-selected: #E9F2FF;
  --ds-background-selected-hovered: #CCE0FF;
  --ds-background-selected-pressed: #85B8FF;
  --ds-background-selected-bold: #0C66E4;
  --ds-background-selected-bold-hovered: #0055CC;
  --ds-background-selected-bold-pressed: #09326C;
  --ds-background-brand-subtlest: #E9F2FF;
  --ds-background-brand-subtlest-hovered: #CCE0FF;
  --ds-background-brand-subtlest-pressed: #85B8FF;
  --ds-background-brand-bold: #0C66E4;
  --ds-background-brand-bold-hovered: #0055CC;
  --ds-background-brand-bold-pressed: #09326C;
  --ds-background-brand-boldest: #092957;
  --ds-background-brand-boldest-hovered: #09326C;
  --ds-background-brand-boldest-pressed: #0055CC;
  --ds-background-danger: #FFEDEB;
  --ds-background-danger-hovered: #FFD2CC;
  --ds-background-danger-pressed: #FF9C8F;
  --ds-background-danger-bold: #CA3521;
  --ds-background-danger-bold-hovered: #AE2A19;
  --ds-background-danger-bold-pressed: #601E16;
  --ds-background-warning: #FFF7D6;
  --ds-background-warning-hovered: #F8E6A0;
  --ds-background-warning-pressed: #F5CD47;
  --ds-background-warning-bold: #E2B203;
  --ds-background-warning-bold-hovered: #CF9F02;
  --ds-background-warning-bold-pressed: #B38600;
  --ds-background-success: #DFFCF0;
  --ds-background-success-hovered: #BAF3DB;
  --ds-background-success-pressed: #7EE2B8;
  --ds-background-success-bold: #1F845A;
  --ds-background-success-bold-hovered: #216E4E;
  --ds-background-success-bold-pressed: #164B35;
  --ds-background-discovery: #F3F0FF;
  --ds-background-discovery-hovered: #DFD8FD;
  --ds-background-discovery-pressed: #B8ACF6;
  --ds-background-discovery-bold: #6E5DC6;
  --ds-background-discovery-bold-hovered: #5E4DB2;
  --ds-background-discovery-bold-pressed: #352C63;
  --ds-background-information: #E9F2FF;
  --ds-background-information-hovered: #CCE0FF;
  --ds-background-information-pressed: #85B8FF;
  --ds-background-information-bold: #0C66E4;
  --ds-background-information-bold-hovered: #0055CC;
  --ds-background-information-bold-pressed: #09326C;
  --ds-blanket: #091E427D;
  --ds-blanket-selected: #388BFF14;
  --ds-blanket-danger: #EF5C4814;
  --ds-interaction-hovered: #00000029;
  --ds-interaction-pressed: #00000052;
  --ds-skeleton: #091E420F;
  --ds-skeleton-subtle: #091E4208;
  --ds-chart-categorical-1: #1D9AAA;
  --ds-chart-categorical-1-hovered: #1D7F8C;
  --ds-chart-categorical-2: #5E4DB2;
  --ds-chart-categorical-2-hovered: #352C63;
  --ds-chart-categorical-3: #D97008;
  --ds-chart-categorical-3-hovered: #B65C02;
  --ds-chart-categorical-4: #943D73;
  --ds-chart-categorical-4-hovered: #50253F;
  --ds-chart-categorical-5: #09326C;
  --ds-chart-categorical-5-hovered: #092957;
  --ds-chart-categorical-6: #8F7EE7;
  --ds-chart-categorical-6-hovered: #8270DB;
  --ds-chart-categorical-7: #50253F;
  --ds-chart-categorical-7-hovered: #421F34;
  --ds-chart-categorical-8: #974F0C;
  --ds-chart-categorical-8-hovered: #5F3811;
  --ds-chart-lime-bold: #6A9A23;
  --ds-chart-lime-bold-hovered: #5B7F24;
  --ds-chart-lime-bolder: #5B7F24;
  --ds-chart-lime-bolder-hovered: #4C6B1F;
  --ds-chart-lime-boldest: #4C6B1F;
  --ds-chart-lime-boldest-hovered: #37471F;
  --ds-chart-neutral: #8590A2;
  --ds-chart-neutral-hovered: #758195;
  --ds-chart-red-bold: #EF5C48;
  --ds-chart-red-bold-hovered: #E34935;
  --ds-chart-red-bolder: #E34935;
  --ds-chart-red-bolder-hovered: #CA3521;
  --ds-chart-red-boldest: #AE2A19;
  --ds-chart-red-boldest-hovered: #601E16;
  --ds-chart-orange-bold: #D97008;
  --ds-chart-orange-bold-hovered: #B65C02;
  --ds-chart-orange-bolder: #B65C02;
  --ds-chart-orange-bolder-hovered: #974F0C;
  --ds-chart-orange-boldest: #974F0C;
  --ds-chart-orange-boldest-hovered: #5F3811;
  --ds-chart-yellow-bold: #B38600;
  --ds-chart-yellow-bold-hovered: #946F00;
  --ds-chart-yellow-bolder: #946F00;
  --ds-chart-yellow-bolder-hovered: #7F5F01;
  --ds-chart-yellow-boldest: #7F5F01;
  --ds-chart-yellow-boldest-hovered: #533F04;
  --ds-chart-green-bold: #22A06B;
  --ds-chart-green-bold-hovered: #1F845A;
  --ds-chart-green-bolder: #1F845A;
  --ds-chart-green-bolder-hovered: #216E4E;
  --ds-chart-green-boldest: #216E4E;
  --ds-chart-green-boldest-hovered: #164B35;
  --ds-chart-teal-bold: #1D9AAA;
  --ds-chart-teal-bold-hovered: #1D7F8C;
  --ds-chart-teal-bolder: #1D7F8C;
  --ds-chart-teal-bolder-hovered: #206B74;
  --ds-chart-teal-boldest: #206B74;
  --ds-chart-teal-boldest-hovered: #1D474C;
  --ds-chart-blue-bold: #388BFF;
  --ds-chart-blue-bold-hovered: #1D7AFC;
  --ds-chart-blue-bolder: #1D7AFC;
  --ds-chart-blue-bolder-hovered: #0C66E4;
  --ds-chart-blue-boldest: #0055CC;
  --ds-chart-blue-boldest-hovered: #09326C;
  --ds-chart-purple-bold: #8F7EE7;
  --ds-chart-purple-bold-hovered: #8270DB;
  --ds-chart-purple-bolder: #8270DB;
  --ds-chart-purple-bolder-hovered: #6E5DC6;
  --ds-chart-purple-boldest: #5E4DB2;
  --ds-chart-purple-boldest-hovered: #352C63;
  --ds-chart-magenta-bold: #DA62AC;
  --ds-chart-magenta-bold-hovered: #CD519D;
  --ds-chart-magenta-bolder: #CD519D;
  --ds-chart-magenta-bolder-hovered: #AE4787;
  --ds-chart-magenta-boldest: #943D73;
  --ds-chart-magenta-boldest-hovered: #50253F;
  --ds-chart-gray-bold: #8590A2;
  --ds-chart-gray-bold-hovered: #758195;
  --ds-chart-gray-bolder: #758195;
  --ds-chart-gray-bolder-hovered: #626F86;
  --ds-chart-gray-boldest: #44546F;
  --ds-chart-gray-boldest-hovered: #2C3E5D;
  --ds-chart-brand: #1D7AFC;
  --ds-chart-brand-hovered: #0C66E4;
  --ds-chart-danger: #EF5C48;
  --ds-chart-danger-hovered: #E34935;
  --ds-chart-danger-bold: #AE2A19;
  --ds-chart-danger-bold-hovered: #601E16;
  --ds-chart-warning: #B38600;
  --ds-chart-warning-hovered: #946F00;
  --ds-chart-warning-bold: #7F5F01;
  --ds-chart-warning-bold-hovered: #533F04;
  --ds-chart-success: #22A06B;
  --ds-chart-success-hovered: #1F845A;
  --ds-chart-success-bold: #216E4E;
  --ds-chart-success-bold-hovered: #164B35;
  --ds-chart-discovery: #8F7EE7;
  --ds-chart-discovery-hovered: #8270DB;
  --ds-chart-discovery-bold: #5E4DB2;
  --ds-chart-discovery-bold-hovered: #352C63;
  --ds-chart-information: #388BFF;
  --ds-chart-information-hovered: #1D7AFC;
  --ds-chart-information-bold: #0055CC;
  --ds-chart-information-bold-hovered: #09326C;
  --ds-surface: #FFFFFF;
  --ds-surface-hovered: #F1F2F4;
  --ds-surface-pressed: #DCDFE4;
  --ds-surface-overlay: #FFFFFF;
  --ds-surface-overlay-hovered: #F1F2F4;
  --ds-surface-overlay-pressed: #DCDFE4;
  --ds-surface-raised: #FFFFFF;
  --ds-surface-raised-hovered: #F1F2F4;
  --ds-surface-raised-pressed: #DCDFE4;
  --ds-surface-sunken: #F7F8F9;
  --ds-shadow-overflow: 0px 0px 8px #091E4229, 0px 0px 1px #091E421F;
  --ds-shadow-overflow-perimeter: #091e421f;
  --ds-shadow-overflow-spread: #091e4229;
  --ds-shadow-overlay: 0px 8px 12px #091E4226, 0px 0px 1px #091E424F;
  --ds-shadow-raised: 0px 1px 1px #091E4240, 0px 0px 1px #091E424F;
  --ds-opacity-disabled: 0.4;
  --ds-opacity-loading: 0.2;
  --ds-UNSAFE-transparent: transparent;
  --ds-elevation-surface-current: #FFFFFF;
}
`